<template>
  <div>
    <product
      @close="dialog = false"
      v-if="dialog"
      ref="product"
      :productId="post.product.id"
      ></product>

    <v-card
      :class="share ? 'rounded-t-0 rounded-b-lg' : 'rounded-0'"
      class="d-flex align-center"
      style="border-bottom: 1px solid #d2d2d2 !important; border-top: 1px solid #d2d2d2 !important;"
      flat
      >
      <v-img
        :class="share ? 'rounded-0 rounded-bl-lg' : 'rounded-0'"
        height="70"
        width="70"
        v-if="post.product.images.length"
        :src="url + post.product.images[0].thumbUrl"
        ></v-img>
      <v-card
        class="rounded-0"
        flat
        height="70"
        width="70"
        v-if="!post.product.images.length"
        color="grey"
        >
      </v-card>
      <v-card-text
        class="d-flex flex-wrap align-center justify-space-between py-0 px-2"
        >
        <div
          class="d-flex flex-column caption"
          >
          <div
            class="font-weight-medium store-card-box black--text"
            >
            {{ post.product.name }}
          </div>
          <div>
            {{ post.product.displayPrice }}
          </div>
        </div>

        <div
          class="ml-auto d-flex mt-1"
          >
          <v-btn
            x-small
            text
            color="primary"
            @click="openProduct"
            class="d-flex align-center"
            >
            <v-icon
              class="mr-1"
              style="margin-top: -2px"
              small
              >
              mdi-shopping-outline
            </v-icon>
            Ver detalle
          </v-btn>

          <v-btn
            x-small
            text
            class="d-flex align-center"
            color="primary"
            v-if="post.store"
            :to="{ name: 'profile', params: { slug: post.store.profile.slug } }"
            >
            <v-icon
              class="mr-1"
              style="margin-top: -2px"
              small
              >
              mdi-storefront
            </v-icon>
            Ver Partner
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const Product = () => import('@/components/products/Product')

export default {
  data: () => ({
    dialog: false
  }),

  props: {
    post: {
      type: Object,
      required: true
    },
    share: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  methods: {
    openProduct () {
      this.dialog = true
      setTimeout( () => {
        this.$refs.product.dialog = true
      }, 100)
    }
  },

  computed: {
    ...mapGetters(['url']),
  },

  components: { Product }
}
</script>
